import { z } from 'zod'

export const UsernameSchema = z
	.string({ required_error: 'Username is required' })
	.min(3, { message: 'Username is too short' })
	.max(20, { message: 'Username is too long' })
	.regex(/^[a-zA-Z0-9_]+$/, {
		message: 'Username can only include letters, numbers, and underscores',
	})
	// users can type the username in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const PasswordSchema = z
	.string({ required_error: 'Password is required' })
	.min(6, { message: 'Password is too short' })
	.max(100, { message: 'Password is too long' })
export const NameSchema = z
	.string({ required_error: 'Name is required' })
	.min(1, { message: 'Name is too short' })
	.max(40, { message: 'Name is too long' })

export const AgeSchema = z
	.number({ required_error: 'age is required' })
	.min(10, { message: 'min age is 10' })
	.max(100, { message: 'max age is 100' })

export const EmailSchema = z
	.string({ required_error: 'Email is required' })
	.email({ message: 'Email is invalid' })
	.min(3, { message: 'Email is too short' })
	.max(100, { message: 'Email is too long' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const MobileNoSchema = z
	.string({ required_error: 'Mobile No. is required' })
	.length(10, { message: 'Mobile No. should be 10 digit.' })

export const OtpSchema = z
	.string({ required_error: 'otp is required' })
	.length(6, { message: 'otp should be 6 digit.' })

export const PanNoSchema = z
	.string({ required_error: 'PAN No. is required' })
	.length(10, { message: 'PAN No. should be 10 digit alphanumeric' })
	.transform(arg => arg.toUpperCase())
	.superRefine((val, ctx) => {
		const panRegx = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
		if (!panRegx.test(val)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'PAN No. should be in correct format.',
			})
		}
	})

export const AadhaarNoSchema = z
	.string({ required_error: 'Aadhaar No. is required' })
	.length(12, { message: 'Aadhaar No. should be 12 digits.' })
	.regex(/[0-9]{12}/, { message: 'Aadhaar No. should be in correct format.' })

export const CityNameSchema = z
	.string({ required_error: 'Current City is required.' })
	.min(2, { message: 'City Name is too short' })
	.max(40, { message: 'City Name is too long' })

export const AccountNoSchema = z
	.string({ required_error: 'Account No. is required' })
	.min(10, { message: 'Account No. is too short' })
	.max(25, { message: 'Account No. is too long' })

export const IFSCCodeSchema = z
	.string({ required_error: 'IFSC code is requred' })
	.length(11, { message: 'IFSC code must be 11 Character' })
	.regex(/[A-Z]{4}0[A-Z0-9]{6}/, {
		message:
			'IFSC code must be in ABCD1234567 format ( first 4 uppercase letters then 7 characters either letters or numbers. )',
	})

export const MaritalStatusSchema = z
	.string({ required_error: 'Marital Status is required.' })
	.min(2, { message: 'Marital Status is too short' })
	.max(40, { message: 'Marital Status is too long' })

export const DateOfBirthSchema = z
	.string({ required_error: 'DOB is required.' })
	.min(2, { message: 'DOB is too short' })
	.max(40, { message: 'DOB is too long' })

export const IncomeSchema = z
	.string({ required_error: 'Income is required.' })
	.min(2, { message: 'Income is too short' })
	.max(40, { message: 'Income is too long' })

export const OccupationSchema = z
	.string({ required_error: 'Occupation is required.' })
	.min(2, { message: 'Occupation is too short' })
	.max(40, { message: 'Occupation is too long' })

export const QualificationSchema = z
	.string({ required_error: 'Qualification is required.' })
	.min(2, { message: 'Qualification is too short' })
	.max(40, { message: 'Qualification is too long' })

export const PincodeSchema = z
	.string({ required_error: 'Pincode is required.' })
	.length(6, { message: 'Pincode should be 6 digit length' })

export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'The passwords must match',
			})
		}
	})
